import { Routes } from '@angular/router';
import { RoleGuard } from './core/guards/role.guard';
import { Role } from './shared/enums/role';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./features/login-professional/login-professional.component').then(
        (m) => m.LoginProfessionalComponent
      ),
  },
  {
    path: 'acesso',
    loadComponent: () =>
      import('./features/login-admin/login-admin.component').then(
        (m) => m.LoginAdminComponent
      ),
  },
  {
    path: 'admin/home',
    loadChildren: () =>
      import('./features/home-admin/home-admin.routes').then(
        (m) => m.HOME_ADMIN_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./features/user-profile/user-profile.routes').then(
        (m) => m.USER_PROFILE_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN, Role.PROFESSIONAL] },
  },
  {
    path: 'customer-profile',
    loadChildren: () =>
      import('./features/customer-profile/customer-profile.routes').then(
        (m) => m.USER_CUSTOMER_PROFILE_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN, Role.PROFESSIONAL] },
  },
  {
    path: 'admin/users',
    loadChildren: () =>
      import('./features/users-clients/users-clients.routes').then(
        (m) => m.USERS_CLIENTS_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: 'admin/customers',
    loadChildren: () =>
      import('./features/users-customers/users-customers.routes').then(
        (m) => m.USERS_CUSTOMERS_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: 'admin/pending-approval',
    loadComponent: () =>
      import(
        './features/pending-approval-professional/pending-approval-professional.component'
      ).then((m) => m.PendingApprovalProfessionalComponent),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: 'admin/services',
    loadChildren: () =>
      import('./features/service-area/service-area.routes').then(
        (m) => m.SERVICE_AREA_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: 'professional/home',
    loadChildren: () =>
      import('./features/home-professional/home-professional.routes').then(
        (m) => m.HOME_PROFESSIONAL_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/service-config',
    loadChildren: () =>
      import('./features/service-config/service-config.routes').then(
        (m) => m.SERVICE_CONFIG_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/map-service',
    loadComponent: () =>
      import('./features/map-service/map-service.component').then(
        (m) => m.MapServiceComponent
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/register',
    loadChildren: () =>
      import(
        './features/product-service-professional/product-service.routes'
      ).then((m) => m.REGISTER_ROUTES),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/appointments',
    loadComponent: () =>
      import('./features/appointments/appointments.component').then(
        (m) => m.AppointmentsComponent
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/bank',
    loadChildren: () =>
      import('./features/bank-professional/bank-professional.routes').then(
        (m) => m.BANK_PROFESSIONAL_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'user/payment-statement',
    loadComponent: () =>
      import('./features/payment-statement/payment-statement.component').then(
        (m) => m.PaymentStatementComponent
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL, Role.ADMIN] },
  },
  {
    path: 'admin/bank',
    loadChildren: () =>
      import('./features/bank-admin/bank-admin.routes').then(
        (m) => m.BANK_ADMIN_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
